.grid-container {
    display: grid;
    /* grid-template-columns: auto auto auto auto auto ; */
    /* grid-template-columns: minmax(auto, 50%) auto auto auto auto ; */
    /* grid-template-columns: repeat(5); */
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); 

    /* height: 100px; */
    /* grid-template-rows: auto auto ;  */
    /* grid-template-rows: repeat(2); */
    
    /* aspect-ratio: 16/9; */

    grid-auto-rows: 148px;
    grid-gap: 5px;
    grid-auto-flow: dense;

    justify-content: space-between;
    justify-items: stretch;
    align-content: center;
    align-items: stretch;
    /* padding: 0px; */
    padding: 1em;
    background-color: white;
}

.grid-items {
    border: 0px solid ;
    width: 100%;
    height: 100%;
    padding: 0%;
    position: relative;
}

.grid-container img {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.head-text {
  position: relative;
  color: white;
}

.center__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Top left text*/
.topleft_text {
  position: absolute;
  top: 87px;
  left: 100px;
  transform: translate(-50%, -50%);
}

.backgroundimg{
  background-image: url('');
  background-repeat: no-repeat;
  background-position: center;
}
